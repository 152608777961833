<template>
  <div id="modelindex">
    <!--内容区域布局-->
    <LiefengContent>
      <!--功能名称，定义则显示-->
      <template v-slot:title>平台发布数据统计</template>
       <!--左则工具条-->
      <template v-slot:toolsbarLeft>
       
      </template>
       <!--右则工具条-->
      <template v-slot:toolsbarRight>
    <div class="tooldiv">区：</div>
    <Select v-model="selectArea" filterable :max-tag-count="2" multiple @on-change="loadAreaList" placeholder="请选择区" clearable style="width:250px">
        <Option v-for="item in areaList" :value="item.value" :key="item.value">{{ item.label }}</Option>
    </Select>
    <div class="tooldiv">街道：</div>
    <Select v-model="selectStree" filterable :max-tag-count="2" multiple @on-change="loadStreeList" style="width:250px" placeholder="请选择街道" clearable :disabled="disabledStree">
        <Option v-for="item in streeList" :value="item.value" :key="item.value">{{ item.label }}</Option>
    </Select>
    <div class="tooldiv"> 社区：</div>
      <Select multiple style="width:290px" filterable :max-tag-count="2" @on-change="loadCommunityList"  v-model="selectedcommunity"  placeholder="全部社区" clearable :disabled="disabledCommunity">
        <Option v-for="item in communitylist" :value="item.value" :key="item.value">{{ item.label }}</Option>
        </Select> 
       <Button type="info" icon="ios-search" @click="loadTotalData">确定</Button>
      </template>
       <!--内容区域-->
      <template v-slot:contentArea>
        <div style="display:flex;align-items:stretch;height:150px;justify-content: space-around;margin:20px 0;">
         <Card  class="cardsty" style="background:#2d8cf0;">
        <div>
            <h1>{{totalData.publishTotal}}</h1>
            <p>累计发布数</p>
        </div>
         </Card>
             <Card  class="cardsty">
        <div>
            <h1>{{totalData.todayAdd}}</h1>
            <p>今日发布数</p>
        </div>
         </Card>
             <Card class="cardsty">
        <div>
            <h1>{{totalData.weekAdd}}</h1>
            <p>本周发布数</p>
        </div>
         </Card>
             <Card class="cardsty">
        <div>
            <h1>{{totalData.monthAdd}}</h1>
            <p>本月发布数</p>
        </div>
         </Card>
            
        </div>

         <Card style="width:96%;margin:50px 2% 0 2%;">
                <div slot="title">分类展示内容发布数：
                  <RadioGroup v-model="timetype" @on-change="loadPublishData">
                <Radio label="all" border>全部</Radio>
               <Radio label="day" border>昨天</Radio>
                <Radio label="week" border>前7天</Radio>
                <Radio label="month" border>前30天</Radio>
                </RadioGroup>
                <span style="margin-left:50px;">自定义时间段：</span>
<DatePicker v-model="selectdate" format="yyyy/MM/dd" type="daterange" placement="bottom-end" placeholder="选择时间区域" style="width: 250px"></DatePicker>
    <Button type="primary" style="margin:0 10px;" icon="ios-search" @click="timetype='custom';loadPublishData();">查询</Button>
    
                </div>
                <p>
                   <VeHistogram :data="chartData" :settings="{label: { show: true}}" ></VeHistogram>
                </p>
                 <p style="text-align:right;">
                   <Button type="default" style="margin:10px;" icon="md-download" @click="exportData">导出表格</Button>
                    </p>
                 <p>
                  <Table ref="lftable"  border :columns="talbeColumns" :data="tableData">
                </Table>
                </p>
                 <p style="text-align:right;margin-top:30px;">
                   <Button type="default" style="margin:10px;" icon="md-download" @click="exportData2">导出表格</Button>
                    </p>
                 <p>
                  <Table ref="fttable"  border :columns="talbe2Columns" :data="table2Data"  show-summary sum-text="全部">
                </Table>
                </p>
          </Card>

      </template>
    </LiefengContent>

  </div>
</template>
<script>
//@route('/statisticscontent')
import LiefengContent from "@/components/LiefengContent3.vue";
import VeHistogram from "v-charts/lib/histogram.common";
import LiefengTable from "@/components/LiefengTable.vue";
export default {
  name: "statisticscontent",
  components: {
    LiefengContent,
    VeHistogram,
    LiefengTable,
  },
  data() {
    return {
      chartData: {},
      talbeColumns: [],
      tableData: [],
      selectdate: [],
      //区列表
      areaCode: "",
      areaList: [],
      selectArea: [],
    
      //街道列表
      streeList: [],
      streeCode: "",
      selectStree: [],
       disabledStree: false,
      //社区列表
      communitylist: [],
      selectedcommunity: [],
      disabledCommunity: false,
      timetype: "all",
      title: "",
      totalData: {},
      talbe2Columns: [
        { title: "类型", key: "menuType", minWidth: 120 },
        { title: "栏目名称", key: "menuName", minWidth: 120 },
        { title: "已发布", key: "published", minWidth: 120 },
        { title: "已归档", key: "archived", minWidth: 120 },
        { title: "点赞量", key: "clicklikePv", minWidth: 120 },
        { title: "浏览量", key: "browsePv", minWidth: 120 },
        { title: "分享量", key: "sharePv", minWidth: 120 },
        { title: "参与人数", key: "participant", minWidth: 120 },
      ],
      table2Data: [],
    };
  },
  created() {
    this.$get("/gx/pc/organization/selectCascadeOrgPermission", {
      //加载城市列表
        adminType: parent.vue.loginInfo.userinfo.adminType || '', //超级管理员标识
        custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
        oemCode: parent.vue.oemInfo.oemCode,
        orgCode: "4401",
        orgCodeSelf: parent.vue.loginInfo.userinfo.orgCode //组织机构编码,目前只有广州市，先写死
    }).then((res) => {
      if (res.code == 200) {
        this.areaList = res.dataList.map((item, index) => {
          return {
            value: item.orgCode,
            label: item.orgName,
          };
        });
      }
    });
  },
  mounted() {
    this.loadTotalData();
  },
  methods: {
    //区选中,获取街道列表
    loadAreaList(value) {
      if(value.length > 1) {   //多选区时，禁用街道和社区
        this.streeCode = [];
        this.selectStree = "";
        this.communityCode = [];
        this.communitylist = [];
        this.streeList = [];
        this.selectedcommunity = "";
        this.disabledStree = true;
        this.disabledCommunity = true;
        return;
      }else if(value.length == 0) {
        this.streeCode = [];
        this.selectStree = "";
        this.communityCode = [];
        this.communitylist = [];
        this.streeList = [];
        this.selectedcommunity = "";
        return;
      }
      this.disabledStree = false;
      this.disabledCommunity = false;
      this.areaCode = value.join(','); 
      this.$get("/gx/pc/organization/selectCascadeOrgPermission", {
        adminType: parent.vue.loginInfo.userinfo.adminType || '', //超级管理员标识
        custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
        oemCode: parent.vue.oemInfo.oemCode,
        orgCode: this.areaCode,
        orgCodeSelf:  parent.vue.loginInfo.userinfo.orgCode  
      }).then((res) => {
        if (res.code == 200) {
          this.streeList = res.dataList.map((item, index) => {
            return {
            value: item.orgCode,
            label: item.orgName,
            };
          });
        }
      });
    },
    //街道选中，获取社区列表
    loadStreeList(value) {
      if(value.length > 1 || this.selectArea.length > 1) {    //禁用社区
        this.communityCode = [];
        this.communitylist = [];
        this.selectedcommunity = "";
        this.disabledCommunity = true;
        return;
      }else if(value.length == 0) {
        this.communityCode = [];
        this.communitylist = [];
        this.selectedcommunity = "";
        return;
      }
       this.disabledCommunity = false;
      this.streeCode = value.join(',');
      this.$get("/gx/pc/organization/selectCascadeOrgPermission", {
        adminType: parent.vue.loginInfo.userinfo.adminType || '', //超级管理员标识
        custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
        oemCode: parent.vue.oemInfo.oemCode,
        orgCode: this.streeCode ,
        orgCodeSelf: parent.vue.loginInfo.userinfo.orgCode  
      }).then((res) => {
        if (res.code == 200) {
          this.communitylist = res.dataList.map((item, index) => {
            return {
              value: item.orgCode,
            label: item.orgName,
            };
          });
        }
      });
    },
    //选中社区
    loadCommunityList(value) {   
      this.communityCode = value.join(','); 
    },
    loadTotalData() {
      //加载全局汇总
      this.$Message.loading({
        content: "数据加载中...",
        duration: 0,
      });
      let codes = '';
      if(this.selectArea.length > 1 || this.selectStree.length == 0) {
        codes = this.selectArea.join(',');
      }else if(this.selectStree.length > 1 || this.selectedcommunity.length == 0){
        codes = this.selectStree.join(',');
      }else {
        codes = this.selectedcommunity.join(',');
      }
      this.$get(
        "/statistic/api/symanage/pc/statColumn/statPublishColumnNotOrg",
        {
          codes: codes,
          oemCode: parent.vue.oemInfo.oemCode,
        }
      )
        .then((res) => {
          if (res && res.data) {
            this.totalData = res.data;
          }
          this.$Message.destroy();
          this.loadPublishData();
        })
        .catch((err) => {
          this.$Message.destroy();
        });
    },
    loadPublishData() {
      //读取内容发布数
      var starttime = "";
      var endtime = "";
      var now = new Date();
      switch (this.timetype) {
        case "all":
          starttime = "2000-01-01 00:00:00";
          endtime = this.$core.formatDate(now, "yyyy-MM-dd 23:59:59");
          this.title = "全部";
          break;
        case "day":
          let yesterday = now.getTime() - 1000*60*60*24;
          starttime = this.$core.formatDate(new Date(yesterday), "yyyy-MM-dd 00:00:00");
          endtime = this.$core.formatDate(new Date(yesterday), "yyyy-MM-dd 23:59:59");
          this.title = "昨天";
          break;
        case "week":
          starttime = this.$core.formatDate(
            new Date(
            now.getTime() - 1000*60*60*24 * 7),
            "yyyy-MM-dd 00:00:00"
          );
          endtime = this.$core.formatDate(
            new Date(
              now.getTime() - 1000*60*60*24
            ),
            "yyyy-MM-dd 23:59:59"
          );
          this.title = "前七天";
          break;
        case "month":
          starttime = this.$core.formatDate(
            new Date(now.getTime() - 1000*60*60*24 * 30),
            "yyyy-MM-dd 00:00:00"
          );
          endtime = this.$core.formatDate(
            new Date(now.getTime() - 1000*60*60*24),
            "yyyy-MM-dd 23:59:59"
          );
          this.title = "前30天";
          break;
        default:
          if (
            this.selectdate.length > 1 &&
            this.selectdate[0] != "" &&
            this.selectdate[1] != ""
          ) {
            starttime = this.$core.formatDate(
              this.selectdate[0],
              "yyyy-MM-dd 00:00:00"
            );
            endtime = this.$core.formatDate(
              this.selectdate[1],
              "yyyy-MM-dd 23:59:59"
            );
            this.title =
              starttime.substring(0, 10) + "~" + endtime.substring(0, 10);
          }
          break;
      }
      if (starttime == "" || endtime == "") {
        this.$Message.error({ content: "请选择时间段", background: true });
        return;
      }
      this.chartData = {};
      this.$Message.loading({
        content: "数据加载中...",
        duration: 0,
      });

      let codes = '';
      if(this.selectArea.length > 1 || this.selectStree.length == 0) {
        codes = this.selectArea.join(',');
      }else if(this.selectStree.length > 1 || this.selectedcommunity.length == 0){
        codes = this.selectStree.join(',');
      }else {
        codes = this.selectedcommunity.join(',');
      }
      this.$get("/statistic/api/symanage/pc/statColumn/statPublishTypeColumnNotOrg", {
        codes: codes,
        startTime: starttime,
        endTime: endtime,
        oemCode: parent.vue.oemInfo.oemCode
      })
        .then((res) => {
          if (res && res.data) {
            this.chartData = res.data;
            this.createTableData(this.chartData.rows || []);
          } 
          if (res && res.data.communityData) {   
            this.table2Data = res.data.communityData;
          }
          this.$Message.destroy();
        })
        .catch((err) => {
          this.$Message.destroy();
        });
    },
    createTableData(chartData) {
      //将图表数据转化为表格数据
      var data = { datetime: this.title };
      this.talbeColumns = [];
      this.tableData = [];
      this.talbeColumns.push({ title: "日期", key: "datetime", minWidth: 80 });
      //console.log(chartData);
      chartData.forEach((item, index) => {
        this.talbeColumns.push({
          title: item.内容分类,
          key: "value" + index,
          minWidth: item.内容分类.length * 20 + 50,
        });
        data["value" + index] = item.发布数;
      });
      this.tableData.push(data);
    },
    exportData() {
      this.$refs.lftable.exportCsv({
        filename: this.title + "分类展示内容发布数",
      });
    },
    exportData2() {
      this.$refs.fttable.exportCsv({
        filename: this.title + "社区发布数",
      });
    },
  },
};
</script>

<style lang="less" scoped>
.tooldiv {
  width: 60px;
  line-height: 30px;
  text-align: right;
  white-space: nowrap;
}
.cardsty {
  min-width: 250px;
  width: 20%;
  background: #2db7f5;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.cardsty p {
  font-size: 16px;
  margin-top: 20px;
}
.selectTree {
  position: relative;
  min-width: 200px;
  /deep/.ivu-select {
    position: absolute;
    left: 0;
    z-index: 999;
    min-width: 200px;
    input {
      padding: 0 26px;
    }
  }
  .ivu-tree {
    height: calc(100vh - 100px);
    min-width: 200px;
    width: 100%;
    position: absolute;
    background: #fbfbfb;
    border: 1px solid #ddd;
    box-shadow: 0px 0px 2px #ddd;
    border-radius: 0 0 5px 5px;
    z-index: 998;
    top: 30px;
    padding: 10px;
    overflow-y: auto;
    /deep/ ul {
      text-align: left;
      line-height: 1.3em;
      .ivu-tree-title-selected,
      .ivu-tree-title-selected:hover {
        background-color: #82d2af;
      }
    }
    /deep/ div {
      text-align: center;
    }
  }
}
</style>
